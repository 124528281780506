import router from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UserService from '../services/user/user.service';
import { SAVE_USER } from '../store/types';
import { ROUTES } from '../utils/constants';

const Home: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    async function checkUser() {
      try {
        const response = await UserService.me();
        const { data } = response?.data;
        dispatch({
          type: SAVE_USER,
          payload: { ...data },
        });
        if (Object.keys(data).length !== 0) {
          router.replace(`/${ROUTES.DASHBOARD}`);
        } else {
          router.replace(`/auth/${ROUTES.AUTH_TYPES.LOGIN}`);
        }
      } catch (er) {
        router.replace(`/auth/${ROUTES.AUTH_TYPES.LOGIN}`);
      }
    }
    checkUser();
  }, []);
  return <></>;
};

export default Home;
